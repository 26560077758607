exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-historie-js": () => import("./../../../src/pages/historie.js" /* webpackChunkName: "component---src-pages-historie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nabidka-bytovych-jednotek-js": () => import("./../../../src/pages/nabidka-bytovych-jednotek.js" /* webpackChunkName: "component---src-pages-nabidka-bytovych-jednotek-js" */),
  "component---src-pages-o-projektu-js": () => import("./../../../src/pages/o-projektu.js" /* webpackChunkName: "component---src-pages-o-projektu-js" */),
  "component---src-pages-okoli-js": () => import("./../../../src/pages/okoli.js" /* webpackChunkName: "component---src-pages-okoli-js" */),
  "component---src-templates-detail-js": () => import("./../../../src/templates/detail.js" /* webpackChunkName: "component---src-templates-detail-js" */)
}

